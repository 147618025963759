/**
 * External imports
 */
import { toInteger } from "lodash";
import { subMinutes, differenceInMinutes, formatISO } from "date-fns";

/**
 * Imports hooks
 */
import { useSelector, useTranslation, useUtils, useAccountSettings } from "..";

/**
 * Imports types
 */
import { GenericObject, AppointmentGroup, Appointment } from "../../types";
import { UpdateAppointmentBody } from "../../hooks/useApi";
import {
  Resource,
  ResourceInstance,
  AppointmentModel,
} from "@devexpress/dx-react-scheduler";
import { useMemo } from "react";

/**
 * Provides utility functions for appointments
 */
export const useAppointmentsUtils = () => {
  const { appointmentGroups } = useSelector((state) => state.account);
  const { includeGroupless } = useSelector((state) => state.appointments);

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets general utils
   */
  const { formatDate } = useUtils();

  /**
   * Gets the account settings
   */
  const { appointmentsStart, appointmentsEnd, appointmentsRequired } =
    useAccountSettings();

  /**
   * Gets the appointments_start flag
   */
  const getAppointmentsStartFlag = () => {
    return appointmentsStart?.value || "07:00";
  };

  /**
   * Gets the appointments_end flag
   */
  const getAppointmentsEndFlag = () => {
    return appointmentsEnd?.value || "18:00";
  };

  /**
   * Checks if a field is required by the account
   */
  const isRequiredByAccount = (field: string) => {
    if (appointmentsRequired) {
      const requiredFields = appointmentsRequired.value.split(",");

      return requiredFields.includes(field);
    }
  };

  /**
   * Gets the minimum start time
   */
  const getMinStartTime = useMemo(() => {
    if (appointmentsStart) {
      return appointmentsStart.value
        .split(":")
        .map((time) => toInteger(time))[0];
    }

    return 7;
  }, [appointmentsStart]);

  /**
   * Gets the maximum end time
   */
  const getMaxEndTime = useMemo(() => {
    if (appointmentsEnd) {
      return appointmentsEnd.value.split(":").map((time) => toInteger(time))[0];
    }

    return 18;
  }, [appointmentsEnd]);

  /**
   * Handles formatting the appointment
   */
  const formatAppointment = (appointment: Appointment) => {
    const timezoneOffsetDate = new Date(appointment.from);
    const offset = Math.abs(timezoneOffsetDate.getTimezoneOffset());

    return {
      ...appointment,
      appointmentGroupId: appointment.appointmentGroupId || 0,
      startDate: subMinutes(new Date(appointment.from), offset),
      endDate: subMinutes(new Date(appointment.to), offset),
      title: appointment.clientName,
    };
  };

  /**
   * Handles formatting the appointments data model
   */
  const formatAppointments = (appointments: Appointment[]) => {
    const newAppointments: AppointmentModel[] = appointments.map(
      (appointment) => formatAppointment(appointment),
    );

    return newAppointments;
  };

  /**
   * Returns the calendar appointment gorups
   */
  const getCalendarAppointmentGroups = (
    appointmentGroups: AppointmentGroup[],
    activeGroups: number[],
    activeOrg: string | number,
  ) => {
    const groups: AppointmentGroup[] = [];

    if (activeGroups.length > 0) {
      const filteredGroups = appointmentGroups.filter((group) => {
        return activeGroups.includes(group.id) || group.id === 0;
      });

      return groups.concat(filteredGroups);
    }

    const groupsByOrganization = appointmentGroups.filter((group) => {
      if (group.id === 0) return true;

      return (
        activeOrg === "all" ||
        group.organizationId.toString() === activeOrg.toString()
      );
    });

    return groups.concat(groupsByOrganization);
  };

  /**
   * Handles filtering the appointments by organization
   */
  const filterAppointmentsByOrganization = (
    appointments: Appointment[],
    activeOrg: string | number,
  ) => {
    return appointments.filter((appointment) => {
      if (activeOrg === "all") return true;

      return appointment.organizationId.toString() === activeOrg.toString();
    });
  };

  /**
   * Converts the appointment groups to resource instances
   */
  const buildResourceInstances = (appointmentGroups: AppointmentGroup[]) => {
    return appointmentGroups.map((group) => {
      return {
        text: group.name,
        id: group.id,
      } as ResourceInstance;
    });
  };

  /**
   * Returns the default resources
   */
  const getDefaultResources = (instances: ResourceInstance[]) => {
    return [
      {
        fieldName: "appointmentGroupId",
        title: t("AppointmentGroup"),
        instances: instances,
        allowMultiple: false,
      },
    ] as Resource[];
  };

  /**
   * Handles normalizing the provided date-like string
   */
  const normalizeDate = (formattedDate: string) => {
    if (formattedDate.includes("/")) {
      const dateParts = formattedDate.split("/").join(" ").split(" ");

      /**
       * Defines the day
       */
      const dd = dateParts[0];

      /**
       * Defines the month
       */
      const mm = dateParts[1];

      /**
       * Defines the year
       */
      const y = dateParts[2];

      /**
       * Defines the hours
       */
      const hh = dateParts[3].split(":")[0];

      /**
       * Defines the minutes
       */
      const min = dateParts[3].split(":")[1];

      /**
       * Builds the date
       */
      const date = new Date(`${mm}/${dd}/${y}`);

      date.setHours(toInteger(hh));
      date.setMinutes(toInteger(min));

      return formatISO(date);
    }
  };

  /**
   * Returns the appointment data for the appointment where changes have been detected
   */
  const findChangedAppointment = (calendar: AppointmentModel[], id: string) => {
    return calendar.find((appointment) => {
      if (appointment.id) return appointment.id.toString() === id.toString();

      return false;
    }) as unknown as Appointment | undefined;
  };

  /**
   * Handles updating the appointment request body
   */
  const buildUpdateAppointmentRequestBody = (
    appointmentData: Appointment,
    changed: GenericObject,
  ) => {
    const appointmentId = Object.keys(changed)[0];
    const { startDate, endDate } = Object.values(changed)[0];

    /**
     * Gets the appointment data
     */
    const {
      phone,
      client,
      carModel,
      carMake,
      clientName,
      description,
      carPlateNumber,
      organizationId,
      requestedServices,
      appointmentGroupId,
      options,
    } = appointmentData;

    const requestBody: UpdateAppointmentBody = {
      id: appointmentId,
      appointmentGroupId: appointmentGroupId,
      carModel: carModel || "",
      carMake: carMake || "",
      carPlateNumber: carPlateNumber || "",
      clientName: clientName || "",
      description: description || "",
      organizationClientId: client?.company?.id || "",
      organizationId: organizationId,
      phone: phone || "",
      requestedServices: requestedServices || [],
      from: normalizeDate(formatDate(startDate, "dd/MM/yyyy HH:mm"))!,
      to: normalizeDate(formatDate(endDate, "dd/MM/yyyy HH:mm")),
      duration: differenceInMinutes(new Date(startDate), new Date(endDate)),
      options: options || {},
    };

    if (!requestBody["phone"]) delete requestBody["phone"];

    return requestBody;
  };

  const formatActiveGroups = (activeGroups: number[]) => {
    const result = activeGroups
      .filter((group) => {
        if (group === 0 && !includeGroupless) return false;
        return true;
      })
      .map((groupId) => {
        const apptGroup = appointmentGroups.find((g) => g.id === groupId);

        if (apptGroup) return apptGroup.name;
        if (groupId === 0) return t("Groupless");
        return groupId;
      })
      .join(", ");

    return result;
  };

  return {
    getMinStartTime,
    getMaxEndTime,
    normalizeDate,
    formatAppointments,
    getDefaultResources,
    buildResourceInstances,
    findChangedAppointment,
    getCalendarAppointmentGroups,
    filterAppointmentsByOrganization,
    buildUpdateAppointmentRequestBody,
    getAppointmentsStartFlag,
    getAppointmentsEndFlag,
    isRequiredByAccount,
    formatActiveGroups,
  };
};

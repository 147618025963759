/**
 * Imports hooks
 */
import { useSelector, useActions } from "../../hooks";

/**
 * Import api hooks
 */
import {
  SearchUsersBody,
  SearchWorkersBody,
  useSearchUsersMutation,
  useSearchWorkersMutation,
} from "../../redux";

/**
 * Imports types
 */
import { FilterModel } from "../../hooks";

export const useAccountUtils = () => {
  const [searchUsers] = useSearchUsersMutation();
  const [searchWorkers] = useSearchWorkersMutation();

  const { accountUsersTable, workersTable } = useSelector(
    (state) => state.account,
  );

  const handleSearchUsers = async (filters: FilterModel[], page?: number) => {
    const { orderBy, orderDir, pageCount } = accountUsersTable;

    if (filters.length === 0) return;

    const reqBody: SearchUsersBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    await searchUsers(reqBody);
  };

  const handleSearchWorkers = async (filters: FilterModel[], page?: number) => {
    const { orderBy, orderDir, pageCount } = workersTable;

    if (filters.length === 0) return;

    const reqBody: SearchWorkersBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    await searchWorkers(reqBody);
  };

  return {
    searchUsers: handleSearchUsers,
    searchWorkers: handleSearchWorkers,
  };
};

import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { WorkOrder, FilterModel, TableData, SortOrder } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  tableRowsLoading: boolean;
  orderBy: string;
  orderDir: SortOrder;
  totalWorkOrders: number;
  pageCount: number;
  workOrders: WorkOrder[];
  activeFilters: FilterModel[];
  modelsInitialized: boolean;
  handleSubmit: (filters: FilterModel[]) => void;
  resetFilters: () => void;
  deleteFilter: (filter: FilterModel) => void;
  handleSearch: (searchValue: string) => void;
  formatActiveFilters: (filters: FilterModel[]) => FilterModel[];
  setPageCount: Dispatch<SetStateAction<number>>;
  setTotalWorkOrders: Dispatch<SetStateAction<number>>;
  setWorkOrders: Dispatch<SetStateAction<WorkOrder[]>>;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleSort: (e: React.MouseEvent<any>, property: keyof TableData) => void;
  updateWorkOrder: (workOrder: WorkOrder) => void;
  exportWorkOrders: () => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  tableRowsLoading: false,
  orderBy: "finished",
  orderDir: "desc",
  totalWorkOrders: 0,
  pageCount: 0,
  workOrders: [],
  activeFilters: [],
  modelsInitialized: false,
  handleSubmit: () => {},
  resetFilters: () => {},
  deleteFilter: () => {},
  handleSearch: () => {},
  formatActiveFilters: () => [],
  setPageCount: () => {},
  setTotalWorkOrders: () => {},
  setWorkOrders: () => {},
  handlePageChange: () => {},
  handleSort: () => {},
  updateWorkOrder: () => {},
  exportWorkOrders: () => Promise.resolve(),
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
